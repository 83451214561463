import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import formatRelative from "date-fns/formatRelative"
import styled from "styled-components"
import Img from "gatsby-image"
import { useMediaQuery } from "@react-hook/media-query"

import BackgroundSection from "../components/background-section"
import Layout from "../layouts"

const StyledBackgroundSection = styled(BackgroundSection)`
  &:not(:first-of-type) {
    margin-top: 2em;
  }
`
export default function News() {
  const mobile = useMediaQuery("only screen and (max-width: 769px)")
  const { posts } = useStaticQuery(
    graphql`
        query {
            posts: allWordpressPost(sort: {fields: date, order: DESC}, limit: 10) {
                nodes {
                    id: wordpress_id
                    slug
                    title
                    excerpt
                    date
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 2000
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )
  return (
    <Layout title="News">
      <div className="container is-widescreen has-text-centered">
        <h1 className="title has-text-primary">
          News
        </h1>
        <p className="subtitle">
          What we have to say
        </p>
      </div>
      {posts.nodes.map((post, i) => (
        <StyledBackgroundSection
          image={post.featured_media.localFile.childImageSharp.fluid}
          opacity="0.5"
          styles={{ minHeight: 400 }}
        >
          <div className="container is-widescreen has-text-centered">
            <div className="head">
              <h2 className="title is-3 has-text-primary">
                <Link
                  key={post.id}
                  to={`/news/${post.slug}`}
                  className="post-link"
                >
                  {post.title}
                </Link>
              </h2>
              <p className="subtitle is-4">
                on {formatRelative(new Date(post.date), new Date())}
              </p>
            </div>
            {mobile && <Img fluid={post.featured_media.localFile.childImageSharp.fluid} fadeIn/>}
            <div className="content" dangerouslySetInnerHTML={{ __html: post.excerpt }}/>
          </div>
        </StyledBackgroundSection>
      ))}
    </Layout>
  )
}
